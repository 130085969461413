<template>
  <v-container fluid>
    <v-card>
      <v-row dense>
        <v-toolbar dense color="secondary" flat>
          <v-toolbar-title>Scan-Logs Report</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-row>
      <v-card-text>
        <v-row dense>
          <v-col cols="3">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="options.FromDate"
                  label="Start Date"
                  readonly
                  v-on="on"
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.FromDate"
                @input="updateRange"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  v-model="options.ToDate"
                  label="End Date"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.ToDate"
                @input="updateRange"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="options.users"
              :items="users"
              attach
              small-chips
              clearable
              deletable-chips
              label="users"
              item-text="name"
              item-value="id"
              multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="options.gates"
              :items="gates"
              attach
              small-chips
              clearable
              deletable-chips
              label="Gates"
              item-text="Name"
              item-value="id"
              multiple
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              v-model="options.search"
              append-icon="mdi-search"
              label="Search Document"
              single-line
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn
              small
              color="primary"
              class="ml-10 white --text"
              right
              @click="getDocData"
            >
              Apply Filters <v-icon>mdi-sync</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-dialog max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  right
                  small
                  class="white--text"
                  color="green"
                  @click="sendNotification"
                  :loading="exportLoader"
                >
                  Export Report <v-icon>mdi-file-excel</v-icon>
                </v-btn>
                <!-- <v-btn
                color="primary"
                @click="sendNotification"
                v-bind="attrs"
                v-on="on"
                >Export Report</v-btn
              > -->
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark>Select Fields</v-toolbar>
                  <v-card-text>
                    <v-row dense>
                      <v-col v-for="(item, index) in Fields" :key="index" cols="3">
                        <v-checkbox
                          v-model="selectedItems"
                          :label="item.label"
                          :value="item.value"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col v-for="(item, index) in FormFields" :key="index" cols="6">
                        <v-checkbox
                          v-model="selectedFormFields"
                          :label="item.title"
                          :value="item.key"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="sendNotification">Ok</v-btn>
                    <v-btn text @click="dialog.value = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="docData"
              dense
              :search="search"
              class="elevation-1"
              :options.sync="options"
              :server-items-length="totalItems"
              :server-search="true"
            >
              <!-- start of posting date template -->
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("Do MMM YYYY")
              }}</template>
              <!-- end of posting date template -->

              <!-- action template -->
              <!-- <template
                  v-slot:item.action="{ item }"
                  v-if="item.ScanLogID"
                >
                  <v-btn
                    icon
                    :to="`/gpm-scan-logs/${item.ScanLogID}`"
                  >
                    <v-icon color="green">mdi-eye</v-icon>
                  </v-btn>
                </template> -->
              <!-- end of action template -->
            </v-data-table>
          </v-col>
        </v-row>
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>

    <!-- category quick add dialog -->
    <v-dialog v-model="errorDocDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="red" dark>
          <v-toolbar-title>Sync Process Error Message</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="errorDocDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                This document was not sync to SAP due to below following error:
              </v-col>
              <v-col cols="12">
                <span>
                  {{ this.ErrorMessage }}
                </span>
              </v-col>

              <v-row>
                <v-col cols="6">
                  <v-btn color="green" dark @click="errorDocDialog = false">Close</v-btn>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <snackbar ref="snackbar"></snackbar>
    <!-- end of modal-->
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    docData: [],
    errorDocDialog: false,
    ErrorMessage: null,
    loading: false,
    selectedItems: [],
    selectedFormFields: [],
    FormFields: [],
    gates: [],
    users: [],
    exportLoader: false,
    headers: [
      { text: "Gate", value: "gates.Name" },
      { text: "creator", value: "creator.name" },
      { text: "Document Type", value: "objecttype.DocumentName" },
      { text: "Document Number", value: "DocNum" },
      { text: "Scan State", value: "ResultDesc" },
      { text: "Scan Time", value: "created_at" },
      { text: "Release Status", value: "ReleaseDesc" },
    ],
    Fields: [
      { label: "Gate", value: "Gate" },
      { label: "Doc Num", value: "DocNum" },
      { label: "Scan Results", value: "state" },
      { label: "Creator", value: "creatorName" },
      { label: "Scan Date", value: "created_at" },
    ],
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 50,
      page: 1,
      FromDate: null,
      ToDate: null,
      search: null,
      users: [],
      gates: [],
    },
    totalItems: 0,
  }),
  watch: {
    options: {
      handler() {
        this.getDocData();
      },
      deep: true,
    },
    // search() {
    //   this.options.page = 1;
    //   if (this.search.length >= 3) {
    //     this.getDocData();
    //   }
    //   if (this.search.length == 0) {
    //     this.getDocData();
    //   }
    // },
  },
  methods: {
    defaultDates() {
      if (!this.options.FromDate) {
        const today = new Date();
        const firstDayOfWeek = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - today.getDay() + 1
        );
        this.options.FromDate = firstDayOfWeek.toISOString().substr(0, 10);
      }
      if (!this.options.ToDate) {
        const lastDayOfWeek = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - new Date().getDay() + 7
        );
        this.options.ToDate = lastDayOfWeek.toISOString().substr(0, 10);
      }
    },

    getDocData() {
      const self = this;
      this.loading = true;
      this.docData = [];

      let url = `/scanLogsReport`;
      if (this.options.FromDate) {
        url += `${url.includes("?") ? "&" : "?"}FromDate=${this.options.FromDate}`;
      }
      if (this.options.search) {
        url += `${url.includes("?") ? "&" : "?"}search=${this.options.search}`;
      }
      if (this.options.ToDate) {
        url += `${url.includes("?") ? "&" : "?"}ToDate=${this.options.ToDate}`;
      }
      url += `${url.includes("?") ? "&" : "?"}page=${this.options.page}&per_page=${
        this.options.itemsPerPage
      }`;

      if (this.options.users?.length > 0) {
        url += `${
          url.includes("?") || url.includes("&") ? "&" : "?"
        }users=${this.options.users.map((p) => `${encodeURIComponent(p)}`).join(",")}`;
      }
      if (this.options.gates?.length > 0) {
        url += `${
          url.includes("?") || url.includes("&") ? "&" : "?"
        }gates=${this.options.gates.map((p) => `${encodeURIComponent(p)}`).join(",")}`;
      }
      this.$store
        .dispatch("get", url)
        .then((res) => {
          console.log(res);
          if (res.ResultCode == 1200) {
            self.docData = res.ResponseData.data;
            self.options.page = res.ResponseData.current_page;
            self.options.itemsPerPage = res.ResponseData.per_page;
            self.totalItems = res.ResponseData.total;
            self.loading = false;
          }
          if (res.ResultCode == 1500) {
            this.$store.commit("loader", false);
            this.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    openerrorDocDialog(item) {
      this.ErrorMessage = item.ErrorMessage;
      this.errorDocDialog = true;
    },
    updateRange() {
      if (
        this.options.FromDate &&
        this.options.ToDate &&
        this.options.FromDate > this.options.ToDate
      ) {
        this.$refs.snackbar.show("Start date cannot be greater than To date", "red");

        this.options.ToDate = "";
      }
    },
    getFormFields() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/app-form-fields`)
        .then((res) => {
          self.FormFields = res.formFields;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    sendNotification() {
      const self = this;
      self.exportLoader = true;
      this.getDocData();
      const data = {
        fields: this.selectedItems,
        attachments: this.selectedFormFields,
        options: this.options,
      };
      const url = "/export_scan_report";
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          console.log(res);
          self.exportLoader = false;
          if (res.ResultCode == 1200) {
            self.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResponseData, "green");
          }
          if (res.ResultCode == 1500) {
            self.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          self.users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getGates() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/gates`)
        .then((res) => {
          self.gates = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getFormFields();
    this.defaultDates();
    this.getUsers();
    this.getGates();
    this.getDocData();
  },
};
</script>
